import map from "lodash/map";

export default ({ table = {}, translations = {}, features = {} }) => {
  const { product_fields: fields, features: featureIds } = table;
  const productFields = map(fields, (p) => translations[p]);
  const featureFields = map(
    featureIds,
    (id) => (features[id] || {}).feature_title
  );
  return [...productFields, ...featureFields];
};
